@use '@angular/material' as mat;

$font-family: 'Poppins', sans-serif;

//PALETTE
$mat-primary: (
  50: #fef4e2,
  100: #fde1b6,
  200: #fcce86,
  300: #fbba57,
  400: #fbab36,
  500: #fa9e21,
  600: #f6921e,
  700: #f0831b,
  800: #e97418,
  900: #df5c15,
  contrast: (50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  )
);
$mat-secondary: (
  50: #e1f5fc,
  100: #b3e5f8,
  200: #81d3f4,
  300: #51c2ef,
  400: #2cb5ed,
  500: #05a8eb,
  600: #009adc,
  700: #0087c9,
  800: #0076b5,
  900: #005793,
  contrast: (50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  )
);

$primary-color: mat.get-color-from-palette($mat-primary, 900);
$secondary-color: mat.get-color-from-palette($mat-secondary, 600);

$bodycolor: #eef5f9;
$headingtext: #455a64;
$bodytext: #67757c;
$sidebar-text: #607d8b;
$sidebar-icons: #99abb4;


$themecolor: #1e88e5;

/*bootstrap Color*/
$danger: #fc4b6c;
$success: #26c6da;
$warning: #ffb22b;
$info: #1e88e5;
$inverse: #2f3d4a;
$muted: #99abb4;
$dark: #263238;
$light: #f2f4f8;
$extra-light: #ebf3f5;
/*Light colors*/
$light-danger: #f9e7eb;
$light-success: #e8fdf8;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #e3f3fd;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;

$danger-dark: #e6294b;
$success-dark: #1eacbe;
$warning-dark: #e9ab2e;
$primary-dark: #6352ce;

/*Normal Color*/
$white: #ffffff;
$purple: #7460ee;

/*Extra Variable*/
$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$radius: 4px;


@mixin xs() {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin gt-sm() {
  @media (max-width: 960px) {
    @content;
  }
}

@mixin gt-md() {
  @media (max-width: 1280px) {
    @content;
  }
}

@mixin gt-lg() {
  @media (max-width: 1920px) {
    @content;
  }
}

@mixin xl() {
  @media (min-width: 1920px) and (max-width: 4999.99px) {
    @content;
  }
}

@mixin lt-xl() {
  @media (max-width: 1919.99px) {
    @content;
  }
}

@mixin lg() {
  @media (min-width: 1280px) and (max-width: 1919.99px) {
    @content;
  }
}