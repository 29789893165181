// global
@use '@angular/material' as mat;

* {
  outline: none;
  box-sizing: border-box;
}


html {
  position: relative;
  min-height: 100%;
}

body {
  margin: 0;
  overflow: hidden;
  font-family: $font-family;
}

html, body {
  height: 100%;
}

h1, h2, h3, h4, h5, h6, p {
  margin-block-start: 0;
}

a {
  color: initial;
  text-decoration: none;
}

.mat-form-field {
  width: 100%;
}

.mat-card {
  box-shadow: none !important;
}
