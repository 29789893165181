/* You can add global styles to this file, and also import other style files */
@import 'front/variable';
@import 'front/theme';

@import 'front/mixins/color';
@import 'front/mixins/font';
@import 'front/mixins/grid';
@import 'front/mixins/spacing';
@import 'front/mixins/weight';

@import 'front/components/sweet';
@import 'front/components/global';
@import 'front/components/display';
@import 'front/components/text';

/* MODAL */
.cdk-overlay-container {
  background: rgba(0, 0, 0, .32);
}
