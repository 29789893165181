.d-flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.d-block {
  display: block;
}

.d-none {
  display: none;
}
